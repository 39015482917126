import { Getter } from "vuex";
import { IState } from './state';

const suggestions: Getter<IState, IState> = (state) => state.suggestions;
const count: Getter<IState, IState> = (state) => state.count;
const showSuggestions: Getter<IState, IState> = (state) => {
	const showAll = state.suggestions.length < state.count;
	return showAll ? state.suggestions : state.suggestions.slice(0, state.count)
}
const showMore: Getter<IState, IState> = (state) => state.count < state.suggestions.length
export default {
	suggestions,
	count,
	showSuggestions,
	showMore
}
