
















import Vue from 'vue';

import { ISuggestion } from '../store/state';

interface SuggestionProps {
	suggestion: ISuggestion
}

export default Vue.extend<{}, {}, {}, SuggestionProps>({
    name: 'Suggestion',
    props: {
      suggestion: {}
    }
});
