







import Vue from 'vue'
import { ISuggestion } from '../store/state'
import Suggestion from '../components/Suggestion.vue'
interface SuggestionsComputed {
	suggestions: ISuggestion[]
}

export default Vue.extend<{}, {}, SuggestionsComputed, {}>({
	name: 'Suggestions',
	computed: {
		suggestions() {
			return this.$store.getters.showSuggestions
		}
	},
	components: {
		Suggestion
	}
})
