export interface ISuggestion {
	name: string,
	img: string,
	uri: string,
	alt: string,
	teaser: string,
	time: string,
	date: string,
	isEvent: boolean,
	location: string

}

export interface IState {
	suggestions: ISuggestion[],
	count: number,
	step: number
}

const deafultState: IState = {
	suggestions: [{name: 'test', img: 'imageURI', alt: '', uri: 'uri', teaser: 'ingen', date: '', time: 'w', isEvent: false, location: 'asd'}],
	count: 3,
	step: 3
}

export default deafultState;
