







import Vue from 'vue'
import Suggestions from './components/Suggestions.vue';
import ShowMore from './components/ShowMore.vue';
// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {

}

interface AppComputed {
	message: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	
	components: {
		Suggestions,
		ShowMore
	}
})
