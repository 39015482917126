








import Vue from 'vue';
import { showMore } from '../store/actions'
import { ISuggestion } from '../store/state';

interface ShowMoreComputed {
	show(): boolean
}
interface ShowMoreMethods {
    showMore(event: Event): void
}

export default Vue.extend<{}, ShowMoreMethods, ShowMoreComputed, {}>({
    name: 'ShowMore',
    computed: {
        show() {
            return this.$store.getters.showMore
            
        }
    },
    methods: {
        showMore(event) {
            this.$store.dispatch(showMore(undefined))            
            const offset = (event.target as HTMLElement).offsetTop;
            scroll({
                top: offset,
                behavior: "smooth"
            });
        }

    }
});
